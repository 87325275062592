const DEVICES = {
  desktop: 'desktop',
  ios: 'ios',
  android: 'android',
}

const TYPES_SOURCES_VIDEOS = {
  vimeo: 'vimeo',
  youtube: 'youtube',
}

/**
 * Scrolls the window to the top of the page with a smooth animation effect.
 * @returns {void}
 */
const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  })
}

/**
 * Native scrollTo with callback
 * @param {number|string} offset - offset to scroll to
 * @param {function} [callback] - callback function
 */
const scrollTo = (offset, callback) => {
  if (!offset || (typeof offset !== 'string' && typeof offset !== 'number')) return

  const fixedOffset = offset.toFixed()
  const onScroll = function () {
    if (window.pageYOffset.toFixed() === fixedOffset) {
      window.removeEventListener('scroll', onScroll)
      callback && callback()
    }
  }

  window.addEventListener('scroll', onScroll)
  onScroll()
  window.scrollTo({
    top: offset,
    behavior: 'smooth',
  })
}

/**
 * Detects whether the current browser is running on an iOS Safari mobile device.
 * @param {string} userAgent - The user agent string of the browser.
 * @returns {boolean} Returns true if the browser is running on an iOS Safari mobile device,
 * otherwise false.
 */
const iOSSafariMobile = (userAgent) => {
  return (
    (/iP(ad|od|hone)/i.test(userAgent) &&
      /WebKit/i.test(userAgent) &&
      !/(CriOS|FxiOS|OPiOS|mercury)/i.test(userAgent)) ||
    // iPad on iOS 13 detection
    (userAgent.includes('Mac') && 'ontouchend' in document)
  )
}

/**
 * Returns true if the current user agent is an iOS device, or if it's running on an iPad with iOS 13 or later.
 * @returns {boolean} True if the current user agent is an iOS device or iPad on iOS 13 or later, false otherwise.
 */
function iOS() {
  return (
    /iP(ad|od|hone)/i.test(navigator.userAgent) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  )
}

/**
 * Determines the current device by inspecting the user agent string, and returns the appropriate device type.
 * @returns {string} One of the values from the DEVICES object: 'ios', 'android', or 'desktop'.
 */
const actualDevice = () => {
  if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    return iOS() ? DEVICES.ios : DEVICES.android
  } else {
    return DEVICES.desktop
  }
}

/**
 * Limits the length of a string to a specified number of characters, and appends a decorator if the string is truncated.
 * @param {string} string - The input string.
 * @param {number} length - The maximum length of the string.
 * @param {string} decorator - The decorator to append to the string if it's truncated (default: '...').
 * @returns {string} The input string, truncated to the specified length (if necessary) and decorated.
 */
const limitStringLength = (string = '', length, decorator = '...', limitInWord = false) => {
  if (string === null) return ''
  if (
    limitInWord &&
    string?.length > length &&
    string.substring(length, length + 1) !== ' ' &&
    string.substring(length - 1, length) !== ' '
  ) {
    return (
      `${string.substring(0, length)}` +
      string.substring(length, string?.length).split(' ')?.[0] +
      `${decorator}`
    )
  } else {
    return string?.length > length ? string.substring(0, length) + `${decorator}` : string
  }
}

/**
 * Crops a string to a specified number of characters.
 * @param {string|undefined|null} content - The input string.
 * @param {number|undefined|null} chars - The maximum length of the string to be returned.
 * @returns {string} The input string, cropped to the specified length (if necessary).
 */
const cropText = (content, chars) => {
  const text = String(content ?? '')
  const res = text.substring(0, chars ?? text.length)
  return res
}

/**
 * Returns a string containing the concatenated content of an FAQ item answer.
 * @param {object} itemContent - An object representing the FAQ item content, with a possible nested structure.
 * @returns {string} - A string containing the concatenated content of the answer, or an empty string if the input is invalid.
 */
const returnContentStringFaqAnswer = (itemContent) => {
  return itemContent?.content
    ? itemContent?.content
        ?.map((contentAsw) => returnContentStringFaqAnswer(contentAsw) || '')
        ?.join(' ')
    : itemContent?.value || ''
}

/**
 * Returns a string without special characters.
 * @param {string} text - Any text containing possible special characters.
 * @returns {string} - A text without special characters.
 */
const removeSpecialCharacters = (text) => {
  const matches = text.match(/[?,&,\\,/,+,#]/g) || []
  for (let i = 0; i < matches.length; i++) {
    const element = matches[i]
    text = text.replaceAll(element, '')
  }
  return text
}

/**
 * Returns a json with the content of the page modified according to the global variables.
 * @param {object} content - an object with page content.
 * @param {object} vars - an object with the global variables stored in the CMS.
 * @returns {object} - An object similar to content, but edited by converting matches to global variables.
 */
const replaceGlobalVars = (content, vars = {}) => {
  let metadata = JSON.stringify(content)
  const matches = metadata.match(/\[ACX-(.+?)\]/g) || []
  for (let i = 0; i < matches.length; i++) {
    const element = matches[i] || ''
    metadata = metadata.replaceAll(element, vars?.[element.replace('[', '').replace(']', '')] ?? '')
  }
  return JSON.parse(metadata)
}

/**
 * Verify booleans, returns valid booleans and converts boolean strings
 * @param {boolean|string} content - The input to verify.
 * @returns {boolean|undefined} The input verified, or return undefined if the prop is not boolean or it's undefined.
 */
const convertStringBoolean = (content) => {
  // Return actual booleans
  if (typeof content === 'boolean') return content

  // Return booleans for strings
  if (/true/i.test(content)) return true
  if (/false/i.test(content)) return false

  // Can't determine boolean
  return undefined
}

/**
 * Return strict value of rich text
 * @param {string|object} content - The input to verify.
 */
const getStrictValueContenfullRichText = (content) => {
  if (typeof content === 'string') return content
  if (typeof content !== 'object') return ''
  return content?.json?.content?.[0]?.content[0]?.value ?? content?.content?.[0]?.content[0]?.value
}

export {
  actualDevice,
  cropText,
  DEVICES,
  iOSSafariMobile,
  limitStringLength,
  returnContentStringFaqAnswer,
  scrollTo,
  scrollToTop,
  TYPES_SOURCES_VIDEOS,
  removeSpecialCharacters,
  replaceGlobalVars,
  convertStringBoolean,
  getStrictValueContenfullRichText,
}
